@import "./vrc-theme-variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// Form

.form-control.vrc-inner-control {
  border        : 0 none;
  border-radius : 0 0 0 0;
  height        : 23px !important;
  line-height   : 20px !important;
  font-size     : $input-font-size !important;
  font-weight   : $input-font-weight !important;
  padding-bottom: 3px !important;

  &:focus {
    height        : 22px !important;
    box-shadow    : none !important;
    padding-bottom: 2px !important;
  }

  &:disabled {
    cursor          : default;
    background-color: $input-disabled-bg !important;
    outline         : 0;
    outline-color   : $input-color;
    outline-style   : none;
    outline-width   : 0px;
  }

  &::placeholder {
    color      : $input-placeholder-color;
    font-weight: $font-weight-normal;
    opacity    : 1;
  }
}

.form-select.vrc-inner-control {
  border        : 0 none;
  border-radius : 0 0 0 0;
  height        : 23px !important;
  line-height   : 20px !important;
  font-size     : $input-font-size !important;
  font-weight   : $input-font-weight !important;
  padding-bottom: 3px !important;

  &:focus {
    height        : 22px !important;
    box-shadow    : none !important;
    padding-bottom: 2px !important;
  }

  &:disabled {
    cursor          : default;
    background-color: $input-disabled-bg !important;
    outline         : 0;
    outline-color   : $input-color;
    outline-style   : none;
    outline-width   : 0px;
  }

  &::placeholder {
    color      : $input-placeholder-color;
    font-weight: $font-weight-normal;
    opacity    : 1;
  }
}

.vrc-select:focus {
  outline      : 0;
  outline-color: $input-color;
  outline-style: none;
  outline-width: 0px;
}

.form-control.vrc-control.component-focus {
  color           : $input-focus-color;
  background-color: $input-focus-bg;
  border-color    : $input-focus-border-color;
  outline         : 0;
  box-shadow      : none;
  border-width    : 2px;
}

.form-control.vrc-control.component-error {
  color           : $danger;
  background-color: #fff;
  border-color    : $danger;
  outline         : 0;
}

.form-control.vrc-control {
  padding-bottom: 0px;
}

.vrc-arrow {
  height: $input-arrow-height;
  cursor: pointer;
}

.form-control.vrc-control.is-required {
  padding-right      : calc(1.5em + 0.75rem) !important;
  background-image   : url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat  : no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size    : calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.vrc-control.disabled {
  background-color: $input-disabled-bg;
  border-color    : $input-disabled-border-color;
  opacity         : 1;
}


.form-control.vrc-control.vrc-select {
  &.disabled {
    background-color: $form-select-disabled-bg !important;
    border-color    : $form-select-disabled-bg !important;
  }

  &:focus {
    outline      : 0;
    outline-color: $input-color;
    outline-style: none;
    outline-width: 0px;
  }
}

.btn.vrc-control {
  box-shadow: none !important;
}

.btn.vrc-calendar {
  padding    : 0px;
  margin     : 0px;
  margin-left: 4px;
  width      : 16px;
  height     : 16px;
}

// table scroller

@keyframes scrooler-expand {
  from {
    width: 1rem;
  }

  to {
    width: 3rem;
  }
}

.table-scrooler {
  top    : 0;
  right  : 0;
  width  : 1rem;
  height : calc(100% - 48.4px);
  z-index: 2;
  opacity: 1;
}

.table-scrooler:hover {
  animation-name           : scrooler-expand;
  animation-duration       : 0.3s;
  animation-iteration-count: 1;
  width                    : 3rem;
}

.table-scrooler:hover>div {
  display: none;
}

.table-scrooler>button {
  width     : 1.5rem !important;
  height    : 100%;
  opacity   : 0;
  box-shadow: none;
  display   : none;
}

.table-scrooler:hover>button {
  animation-name           : scrooler-expand;
  animation-duration       : 0.3s;
  animation-iteration-count: 1;
  width                    : 1.5rem !important;
  opacity                  : 0.8;
  display                  : block;

  >i {
    margin-left: -3px;
  }

  &.btn-left {
    border-top-right-radius   : 0px;
    border-bottom-right-radius: 0px;
  }

  &.btn-right {
    border-top-left-radius   : 0px;
    border-bottom-left-radius: 0px;
  }
}

// login

@include media-breakpoint-up(xs) {
  .vrc-login-padding {
    padding-left : 8px;
    padding-right: 8px;
    padding-top  : 109px;
  }

  .vrc-login {
    width: 100%;
  }
}

@include media-breakpoint-up(sm) {
  .vrc-login-padding {
    padding-left: 60px;
    padding-top : 109px;
  }

  .vrc-login {
    width: 338px;
  }
}

@include media-breakpoint-up(md) {
  .vrc-login-padding {
    padding-left: 60px;
    padding-top : 109px;
  }

  .vrc-login {
    width: 338px;
  }
}

@include media-breakpoint-up(lg) {
  .vrc-login-padding {
    padding-left: 60px;
    padding-top : 109px;
  }

  .vrc-login {
    width: 338px;
  }
}

@include media-breakpoint-up(xl) {
  .vrc-login-padding {
    padding-left: 60px;
    padding-top : 109px;
  }

  .vrc-login {
    width: 338px;
  }
}

.vrc-login-row {
  background: url('./assets/login-background.png');
  background-repeat: repeat;
  height: 100vh;
}

.vrc-login-title {
  font-size  : 13px;
  line-height: 18px;
}

.vrc-login {
  box-shadow   : 0px 3px 10px rgba(0, 158, 195, 0.05);
  border-radius: 6px;
}

.vrc-login-top {
  height        : 192px;
  background    : $white;
  border-bottom : 1px solid #E9EDF5;
  box-shadow    : 0px 2px 5px rgba(0, 158, 195, 0.05), 0px 3px 10px rgba(0, 0, 0, 0.05), inset 0px -2px 0px #E7E7E7;
  border-radius : 6px 6px 0px 0px;
  padding-top   : 36px;
  padding-bottom: 36px;

  >.w-100 {
    margin-bottom: 19px;
  }
}
.vrc-chat-text{
  font-size: 13px;
  font-weight: $font-weight-bolder;
  line-height: 18px;
  color: $black;
}
.vrc-chat-timestamp{
  font-size: 13px;
  font-weight: $font-weight-normal;
  line-height: 18px;
  color: $gray400;
}