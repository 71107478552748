.vi::before,
[class^="vi-"]::before,
[class*=" vi-"]::before {
    display                : inline-block;
    font-style             : normal;
    font-weight            : normal !important;
    font-variant           : normal;
    text-transform         : none;
    line-height            : 1;
    vertical-align         : -.25em;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.vi-logout::before {
    content: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.33333 3.33333L8.39333 4.27333L9.44667 5.33333H4V6.66667H9.44667L8.39333 7.72L9.33333 8.66667L12 6L9.33333 3.33333ZM1.33333 1.33333H6V0H1.33333C0.6 0 0 0.6 0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12H6V10.6667H1.33333V1.33333Z' fill='%23464F60'/%3E%3C/svg%3E");
}

.vi-editpen::before {
    content: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.8051 2.69462C12.065 2.43466 12.065 2.01472 11.8051 1.75476L10.2453 0.194973C9.986 -0.0649909 9.56539 -0.0649909 9.30543 0.194973L8.0856 1.4148L10.5853 3.91445L11.8051 2.69462ZM0 9.50033V12H2.49965L9.87196 4.62768L7.37231 2.12802L0 9.50033Z' fill='white'/%3E%3C/svg%3E%0A");
}

.vi-person-add-alt::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_342_3101)'%3E%3Cpath d='M13.8182 5.63636V3.45454H12.3636V5.63636H10.1818V7.09091H12.3636V9.27273H13.8182V7.09091H16V5.63636H13.8182Z' fill='white'/%3E%3Cg clip-path='url(%23clip1_342_3101)'%3E%3Cpath d='M5.5 8C7.23594 8 8.64286 6.65703 8.64286 5C8.64286 3.34297 7.23594 2 5.5 2C3.76406 2 2.35714 3.34297 2.35714 5C2.35714 6.65703 3.76406 8 5.5 8ZM7.7 8.75H7.28996C6.74487 8.98906 6.13839 9.125 5.5 9.125C4.86161 9.125 4.25759 8.98906 3.71004 8.75H3.3C1.47812 8.75 0 10.1609 0 11.9V12.875C0 13.4961 0.527902 14 1.17857 14H9.82143C10.4721 14 11 13.4961 11 12.875V11.9C11 10.1609 9.52187 8.75 7.7 8.75Z' fill='white'/%3E%3C/g%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_342_3101'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3CclipPath id='clip1_342_3101'%3E%3Crect width='11' height='12' fill='white' transform='translate(0 2)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.vi-search::before {
    content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.20297 8.80503C3.20983 8.80503 1.60091 7.19611 1.60091 5.20297C1.60091 3.20983 3.20983 1.60091 5.20297 1.60091C7.19611 1.60091 8.80503 3.20983 8.80503 5.20297C8.80503 7.19611 7.19611 8.80503 5.20297 8.80503ZM10.0057 8.80503H9.37336L9.14923 8.58891C9.93368 7.67639 10.4059 6.49171 10.4059 5.20297C10.4059 2.32933 8.07662 0 5.20297 0C2.32933 0 0 2.32933 0 5.20297C0 8.07662 2.32933 10.4059 5.20297 10.4059C6.49171 10.4059 7.67639 9.93368 8.58891 9.15003L8.80503 9.37336V10.0057L12.8073 14L14 12.8073L10.0057 8.80503Z' fill='white'/%3E%3C/svg%3E%0A");
}

.vi-local-offer::before {
    content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.2733 6.72L7.27331 0.720003C7.03331 0.480002 6.69998 0.333336 6.33331 0.333336H1.66665C0.933313 0.333336 0.333313 0.933336 0.333313 1.66667V6.33334C0.333313 6.7 0.47998 7.03334 0.726646 7.28L6.72665 13.28C6.96665 13.52 7.29998 13.6667 7.66665 13.6667C8.03331 13.6667 8.36665 13.52 8.60665 13.2733L13.2733 8.60667C13.52 8.36667 13.6666 8.03334 13.6666 7.66667C13.6666 7.3 13.5133 6.96 13.2733 6.72ZM2.66665 3.66667C2.11331 3.66667 1.66665 3.22 1.66665 2.66667C1.66665 2.11334 2.11331 1.66667 2.66665 1.66667C3.21998 1.66667 3.66665 2.11334 3.66665 2.66667C3.66665 3.22 3.21998 3.66667 2.66665 3.66667Z' fill='white'/%3E%3C/svg%3E%0A");
}

.vi-file-download::before {
    content: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.5 8.25001V10.5H1.5V8.25001H0V10.5C0 11.325 0.675 12 1.5 12H10.5C11.325 12 12 11.325 12 10.5V8.25001H10.5ZM9.75 5.25001L8.6925 4.19251L6.75 6.12751V1.14441e-05H5.25V6.12751L3.3075 4.19251L2.25 5.25001L6 9.00001L9.75 5.25001Z' fill='white'/%3E%3C/svg%3E%0A");
}

.vi-file-download-dark::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 10.25V12.5H3.5V10.25H2V12.5C2 13.325 2.675 14 3.5 14H12.5C13.325 14 14 13.325 14 12.5V10.25H12.5ZM11.75 7.25001L10.6925 6.19251L8.75 8.12751V2.00001H7.25V8.12751L5.3075 6.19251L4.25 7.25001L8 11L11.75 7.25001Z' fill='%235E5ADB'/%3E%3C/svg%3E%0A");
}

.vi-dropdown-down::before {
    content: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 0L5 5L10 0H0Z' fill='white'/%3E%3C/svg%3E%0A");
}

.vi-dropdown-up::before {
    content: url("data:image/svg+xml,%3Csvg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 5L5 -4.37114e-07L0 5L10 5Z' fill='white'/%3E%3C/svg%3E%0A");
}

.vi-plus::before {
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.28784 1.64108L7.28834 7.28834L1.64108 7.28784V8.71215H7.28784L7.28834 14.3594L8.71215 14.3589V8.71215H14.3589L14.3594 7.28834L8.71215 7.28784V1.64108H7.28784Z' fill='%23464F60'/%3E%3C/svg%3E%0A");
}

.vi-plus-white::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.28787 1.64109L7.28837 7.28835L1.64111 7.28784V8.71216H7.28787L7.28837 14.3594L8.71218 14.3589V8.71216H14.3589L14.3594 7.28835L8.71218 7.28784V1.64109H7.28787Z' fill='white'/%3E%3C/svg%3E%0A");
}

.vi-add-hover::before {
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10' r='10' fill='%23203087'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.28787 3.64109L9.28837 9.28835L3.64111 9.28784V10.7122H9.28787L9.28837 16.3594L10.7122 16.3589V10.7122H16.3589L16.3594 9.28835L10.7122 9.28784V3.64109H9.28787Z' fill='white'/%3E%3C/svg%3E%0A");
}

.vi-add-circle::before {
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10' r='10' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.28784 3.64109L9.28834 9.28835L3.64108 9.28784V10.7122H9.28784L9.28834 16.3594L10.7122 16.3589V10.7122H16.3589L16.3594 9.28835L10.7122 9.28784V3.64109H9.28784Z' fill='%23203087'/%3E%3C/svg%3E%0A");
}

.vi-open-in-new::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6667 12.6667H3.33333V3.33333H7V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V9H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L7 8.06L7.94 9L12.6667 4.27333V6.66667H14V2H9.33333Z' fill='%23203087'/%3E%3C/svg%3E%0A");
}

.vi-popup::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.25 5H3C2.44772 5 2 5.44772 2 6V13C2 13.5523 2.44772 14 3 14H10C10.5523 14 11 13.5523 11 13V11.75H9.8V12.8H3.2V6.2H4.25V5Z' fill='%235E5ADB'/%3E%3Cmask id='path-2-inside-1_566_11403' fill='white'%3E%3Crect x='5' y='2' width='9' height='9' rx='1'/%3E%3C/mask%3E%3Crect x='5' y='2' width='9' height='9' rx='1' stroke='%235E5ADB' stroke-width='2.4' mask='url(%23path-2-inside-1_566_11403)'/%3E%3C/svg%3E%0A");
}

.vi-copycontent::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 2H9V3.2H3.2V10H2V3C2 2.44772 2.44772 2 3 2Z' fill='%235E5ADB'/%3E%3Cmask id='path-2-inside-1_568_11465' fill='white'%3E%3Crect x='4' y='4' width='9' height='10' rx='1'/%3E%3C/mask%3E%3Crect x='4' y='4' width='9' height='10' rx='1' stroke='%235E5ADB' stroke-width='2.4' mask='url(%23path-2-inside-1_568_11465)'/%3E%3C/svg%3E%0A");
}

.vi-calendar::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.2 13H12.8V5.20001H3.2V13ZM12.8 2.2H12.2V1H11V2.2H5V1H3.8V2.2H3.2C2.54 2.2 2 2.74 2 3.4V13C2 13.66 2.54 14.2 3.2 14.2H12.8C13.46 14.2 14 13.66 14 13V3.4C14 2.74 13.46 2.2 12.8 2.2Z' fill='%23444444'/%3E%3C/svg%3E%0A");
}

.vi-check::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.75 7.62L6.5 11.37L13.25 4.62' stroke='%23464F60' stroke-width='1.5' stroke-linecap='square'/%3E%3C/svg%3E%0A");
}

.vi-check-white::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.75 7.62L6.5 11.37L13.25 4.62' stroke='white' stroke-width='1.5' stroke-linecap='square'/%3E%3C/svg%3E%0A");
}

.vi-check-circle::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_613_61814)'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23203087'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8484 5.36039L6.85714 11.1853L3.15155 7.58262L3.98804 6.72222L6.85714 9.51162L12.0119 4.5L12.8484 5.36039Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_613_61814'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.vi-check-circle-hover::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_613_61813)'%3E%3Ccircle cx='8' cy='8' r='8' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8484 5.36039L6.85714 11.1853L3.15155 7.58262L3.98804 6.72222L6.85714 9.51162L12.0119 4.5L12.8484 5.36039Z' fill='%23203087'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_613_61813'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.vi-times::before {
    content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.20311 4.9384L0.62166 1.35696L1.41856 0.560059L5.00001 4.14151L8.58144 0.560068L9.37834 1.35697L5.79691 4.9384L9.50165 8.64315L8.70475 9.44005L5.00001 5.7353L1.29525 9.44006L0.498352 8.64316L4.20311 4.9384Z' fill='%23464F60'/%3E%3C/svg%3E%0A");
}

.vi-times-white::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.9929 3L8 6.99357L4.00714 3L3 4.00714L6.99286 8L3 11.9936L4.00714 13L8 9.00714L11.9929 13L13 11.9936L9.00714 8L13 4.00714L11.9929 3Z' fill='white'/%3E%3C/svg%3E%0A");
}

.vi-times-circle::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_613_61510)'%3E%3Ccircle cx='8' cy='8' r='8' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.1943 4L8 7.19486L4.80571 4L4 4.80571L7.19429 8L4 11.1949L4.80571 12L8 8.80571L11.1943 12L12 11.1949L8.80571 8L12 4.80571L11.1943 4Z' fill='%23203087'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_613_61510'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.vi-times-circle-hover::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_613_61790)'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23203087'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.1943 4L8 7.19486L4.80571 4L4 4.80571L7.19429 8L4 11.1949L4.80571 12L8 8.80571L11.1943 12L12 11.1949L8.80571 8L12 4.80571L11.1943 4Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_613_61790'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.vi-right::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 11L9.5 8L6.5 5' stroke='%23203087' stroke-width='1.2' stroke-linecap='square'/%3E%3C/svg%3E%0A");
}

.vi-right-circle::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_613_73121)'%3E%3Ccircle cx='8' cy='8' r='8' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 11.2195L9.2326 8L6 4.78052L6.7837 4L10.8 8L6.7837 12L6 11.2195Z' fill='%23203087'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_613_73121'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.vi-right-circle-hover::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_613_73122)'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23203087'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 11.2195L9.2326 8L6 4.78052L6.7837 4L10.8 8L6.7837 12L6 11.2195Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_613_73122'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.vi-column-sorting::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 14L4 9H12L8 14Z' fill='%23A1A9B8'/%3E%3Cpath d='M8 2L4 7H12L8 2Z' fill='%23464F60'/%3E%3C/svg%3E%0A");
}

.vi-column-sortingnon::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 14L4 9H12L8 14Z' fill='%23A1A9B8'/%3E%3Cpath d='M8 2L4 7H12L8 2Z' fill='%23A1A9B8'/%3E%3C/svg%3E%0A");
}

.vi-column-sortingdown::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 14L4 9H12L8 14Z' fill='%23464F60'/%3E%3Cpath d='M8 2L4 7H12L8 2Z' fill='%23A1A9B8'/%3E%3C/svg%3E%0A");
}

.vi-person-off::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.8067 10.9767L11.56 8.72332C11.9867 8.86999 12.38 9.04332 12.74 9.22999C13.3867 9.56999 13.7934 10.2433 13.8067 10.9767ZM14.6 13.6567L13.66 14.5967L11.92 12.8633H3.14002V11.01C3.14002 10.2633 3.54669 9.57665 4.21336 9.23665C5.07336 8.79665 6.12669 8.42332 7.32669 8.26999L1.40002 2.34332L2.34002 1.40332L14.6 13.6567ZM10.5867 11.53L8.58669 9.52999C8.54669 9.52999 8.51336 9.52999 8.47336 9.52999C6.89336 9.52999 5.61336 10.0167 4.82002 10.4233C4.60669 10.53 4.47336 10.7567 4.47336 11.01V11.53H10.5867ZM8.47336 3.52999C9.20669 3.52999 9.80669 4.12999 9.80669 4.86332C9.80669 5.43665 9.44669 5.92332 8.94002 6.10999L9.92669 7.09665C10.66 6.62332 11.14 5.79665 11.14 4.86332C11.14 3.38999 9.94669 2.19665 8.47336 2.19665C7.54002 2.19665 6.71336 2.67665 6.24002 3.40999L7.22669 4.39665C7.41336 3.88999 7.90002 3.52999 8.47336 3.52999Z' fill='%2379CAA8'/%3E%3C/svg%3E%0A");
}

.vi-person-off-white::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.8067 10.9767L11.56 8.72332C11.9867 8.86999 12.38 9.04332 12.74 9.22999C13.3867 9.56999 13.7933 10.2433 13.8067 10.9767ZM14.6 13.6567L13.66 14.5967L11.92 12.8633H3.13999V11.01C3.13999 10.2633 3.54666 9.57665 4.21333 9.23665C5.07333 8.79665 6.12666 8.42332 7.32666 8.26999L1.39999 2.34332L2.33999 1.40332L14.6 13.6567ZM10.5867 11.53L8.58666 9.52999C8.54666 9.52999 8.51333 9.52999 8.47333 9.52999C6.89333 9.52999 5.61333 10.0167 4.81999 10.4233C4.60666 10.53 4.47333 10.7567 4.47333 11.01V11.53H10.5867ZM8.47333 3.52999C9.20666 3.52999 9.80666 4.12999 9.80666 4.86332C9.80666 5.43665 9.44666 5.92332 8.93999 6.10999L9.92666 7.09665C10.66 6.62332 11.14 5.79665 11.14 4.86332C11.14 3.38999 9.94666 2.19665 8.47333 2.19665C7.53999 2.19665 6.71333 2.67665 6.23999 3.40999L7.22666 4.39665C7.41333 3.88999 7.89999 3.52999 8.47333 3.52999Z' fill='%23FADEE1'/%3E%3C/svg%3E%0A");
}

.vi-arrow-forward::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.00002 2.66669L7.06002 3.60669L10.78 7.33335H2.66669V8.66669H10.78L7.06002 12.3934L8.00002 13.3334L13.3334 8.00002L8.00002 2.66669Z' fill='%23203087'/%3E%3C/svg%3E%0A");
}

.vi-swap-horiz::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.66 7.33331L2 9.99998L4.66 12.6666V10.6666H9.33333V9.33331H4.66V7.33331ZM14 5.99998L11.34 3.33331V5.33331H6.66667V6.66665H11.34V8.66665L14 5.99998Z' fill='%235E5ADB'/%3E%3C/svg%3E%0A");
}

.vi-trash::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-trash' viewBox='0 0 16 16'%3E%3Cpath d='M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z'/%3E%3Cpath fill-rule='evenodd' d='M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z'/%3E%3C/svg%3E");
}
.vi-trash-hover::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#FFFFFF' class='vi vi-trash' viewBox='0 0 16 16'%3E%3Cpath d='M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z'/%3E%3Cpath fill-rule='evenodd' d='M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z'/%3E%3C/svg%3E");
}

.vi-rectangle::before {
    content: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='6' height='6' rx='2' fill='%23464F60'/%3E%3C/svg%3E%0A");
}

.vi-rectangle-white::before {
    content: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='6' height='6' rx='2' fill='white'/%3E%3C/svg%3E%0A");
}

.vi-radio-minus::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='7.5' fill='%23D5DBE5'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13 8.5L3 8.5V7.5L13 7.5V8.5Z' fill='%23464F60'/%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='7.5' stroke='%23D5DBE5'/%3E%3C/svg%3E%0A");
}

.vi-radio-check::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='7.5' fill='%23D1EEE2'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.5 5.67172L6.90741 11L3.5 7.75363L4.16642 7.0819L6.90741 9.69336L11.8336 5L12.5 5.67172Z' fill='%23464F60'/%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='7.5' stroke='%23D1EEE2'/%3E%3C/svg%3E%0A");
}

.vi-radio-times::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='7.5' fill='%23FADEE1'/%3E%3Crect x='0.5' y='0.5' width='15' height='15' rx='7.5' stroke='%23FADEE1'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.29191 7.94446L4.10957 4.71793L4.81766 4L8 7.22653L11.1823 4.00001L11.8904 4.71794L8.7081 7.94446L12 11.2821L11.2919 12L8 8.66238L4.7081 12L4 11.2821L7.29191 7.94446Z' fill='%23464F60'/%3E%3C/svg%3E%0A");
}

.vi-play-circle-filled::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.00004 1.33325C4.32004 1.33325 1.33337 4.31992 1.33337 7.99992C1.33337 11.6799 4.32004 14.6666 8.00004 14.6666C11.68 14.6666 14.6667 11.6799 14.6667 7.99992C14.6667 4.31992 11.68 1.33325 8.00004 1.33325ZM6.66671 10.9999V4.99992L10.6667 7.99992L6.66671 10.9999Z' fill='%235E5ADB'/%3E%3C/svg%3E%0A");
}

.vi-chevron-up::before {
    content: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 7L6 2L1 7' stroke='white' stroke-width='2'/%3E%3C/svg%3E%0A");
}

.vi-chevron-down::before {
    content: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%23464F60' stroke-width='2'/%3E%3C/svg%3E%0A");
}

.vi-chevron-left::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='vi vi-chevron-left' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
}

.vi-chevron-right::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='vi vi-chevron-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.vi-caret-left-fill::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='vi vi-caret-left-fill' viewBox='0 0 16 16'%3E%3Cpath d='m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z'/%3E%3C/svg%3E");
}

.vi-dot-circle::before {
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='10' fill='%23D5DBE5'/%3E%3Ccircle cx='10' cy='10' r='4' fill='%23464F60'/%3E%3C/svg%3E%0A");
}

.vi-playlist-add::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.99996 5.99998H1.66663V7.33331H8.99996V5.99998ZM8.99996 3.33331H1.66663V4.66665H8.99996V3.33331ZM11.6666 8.66665V5.99998H10.3333V8.66665H7.66663V9.99998H10.3333V12.6666H11.6666V9.99998H14.3333V8.66665H11.6666ZM1.66663 9.99998H6.33329V8.66665H1.66663V9.99998Z' fill='%235E5ADB'/%3E%3C/svg%3E%0A");
}

.vi-arrow-circle::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_897_1364)'%3E%3Ccircle cx='8' cy='8' r='8' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 11.2195L9.2326 8L6 4.78052L6.7837 4L10.8 8L6.7837 12L6 11.2195Z' fill='%23203087'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_897_1364'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.vi-arrow-circle-hover::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_937_1060)'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23203087'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 11.2195L9.2326 8L6 4.78052L6.7837 4L10.8 8L6.7837 12L6 11.2195Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_937_1060'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.vi-assigned::before {
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 576 512' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M228.3 469.1L47.6 300.4c-4.2-3.9-8.2-8.1-11.9-12.4l87 0c22.6 0 43-13.6 51.7-34.5l10.5-25.2 49.3 109.5c3.8 8.5 12.1 14 21.4 14.1s17.8-5 22-13.3L320 253.7l1.7 3.4c9.5 19 28.9 31 50.1 31l104.5 0c-3.7 4.3-7.7 8.5-11.9 12.4L283.7 469.1c-7.5 7-17.4 10.9-27.7 10.9s-20.2-3.9-27.7-10.9zM503.7 240l-132 0c-3 0-5.8-1.7-7.2-4.4l-23.2-46.3c-4.1-8.1-12.4-13.3-21.5-13.3s-17.4 5.1-21.5 13.3l-41.4 82.8L205.9 158.2c-3.9-8.7-12.7-14.3-22.2-14.1s-18.1 5.9-21.8 14.8l-31.8 76.3c-1.2 3-4.2 4.9-7.4 4.9L16 240c-2.6 0-5 .4-7.3 1.1C3 225.2 0 208.2 0 190.9l0-5.8c0-69.9 50.5-129.5 119.4-141C165 36.5 211.4 51.4 244 84l12 12 12-12c32.6-32.6 79-47.5 124.6-39.9C461.5 55.6 512 115.2 512 185.1l0 5.8c0 16.9-2.8 33.5-8.3 49.1z' style='fill: %23EF5466;' /%3E%3C/svg%3E%0A");
}

.vi-logo-title::before {
    content: url(./assets/logo-title.svg);
}

.vi-logo::before {
    content: url(./assets/logo.svg);
}