@import "./vrc-theme-variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// Common
select>option {
    padding: 8px 8px !important;
    margin: 8px 8px !important;
    font-size: 13px !important;
    line-height: 18px !important;
    color: $gray700 !important;
    border-radius: 4px !important;

    &:hover {
        background: $lightblueBG;
    }
}

.row.sticky-top {
    background-color: $lightblueBG;
}

.fs-7 {
    font-size: .75rem !important;
    line-height: $line-height-base * 1.34;
}

.bg-loyalty-details {
    background-color: $back_table;
}

// Buttons

.btn {
    height: 32px;
    letter-spacing: 0.03em;
    text-shadow: none;

    &:hover {
        >i.vi.vi-times-circle::before {
            content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_613_61790)'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23203087'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.1943 4L8 7.19486L4.80571 4L4 4.80571L7.19429 8L4 11.1949L4.80571 12L8 8.80571L11.1943 12L12 11.1949L8.80571 8L12 4.80571L11.1943 4Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_613_61790'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }

        >i.vi.vi-check-circle::before {
            content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_613_61813)'%3E%3Ccircle cx='8' cy='8' r='8' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8484 5.36039L6.85714 11.1853L3.15155 7.58262L3.98804 6.72222L6.85714 9.51162L12.0119 4.5L12.8484 5.36039Z' fill='%23203087'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_613_61813'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }

        >i.vi.vi-arrow-circle::before {
            content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_937_1060)'%3E%3Ccircle cx='8' cy='8' r='8' fill='%23203087'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 11.2195L9.2326 8L6 4.78052L6.7837 4L10.8 8L6.7837 12L6 11.2195Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_937_1060'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }

        >i.vi.vi-trash::before {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23FFFFFF' class='vi vi-trash' viewBox='0 0 16 16'%3E%3Cpath d='M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z'/%3E%3Cpath fill-rule='evenodd' d='M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z'/%3E%3C/svg%3E");
        }
    }
}

.btn-lg {
    height: 40px;
    line-height: 18px !important;
}

.btn-sm {
    height: 28px;
    line-height: 18px !important;
}

.btn-primary {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05), inset 0px -2px 0px #122274;
    text-shadow: 0px 1px 0px #162575;

    &:disabled {
        background-color: $gray100 !important;
        box-shadow: 0px 2px 5px rgba(0, 158, 195, 0.05), 0px 3px 10px rgba(0, 0, 0, 0.05), inset 0px -2px 0px $gray100;
    }

    &:hover {
        background-color: $hover_blue !important;
        border-right-color: $hover_blue !important;
    }

    >i.vi.vi-editpen::before {
        vertical-align: middle;
    }

    >i.vi.vi-chevron-up::before {
        vertical-align: 0px;
    }
}

.btn-secondary {
    box-shadow: 0px 2px 5px rgba(0, 158, 195, 0.05), 0px 3px 10px rgba(0, 0, 0, 0.05), inset 0px -2px 0px #E7E7E7;
    color: $gray700 !important;

    >i.vi.vi-chevron-down::before {
        vertical-align: 0px;
    }
}

.btn-logo {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1), inset 0px -2px 0px #CEE2EF;

    &:hover {
        background-color: #CEE2EF !important;
    }

    >img {
        height: 1.5rem !important;
    }
}

.btn-popup {
    letter-spacing: normal;

    &:hover {
        >i.vi.vi-popup::before {
            content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.25 5H3C2.44772 5 2 5.44772 2 6V13C2 13.5523 2.44772 14 3 14H10C10.5523 14 11 13.5523 11 13V11.75H9.8V12.8H3.2V6.2H4.25V5Z' fill='white'/%3E%3Cmask id='path-2-inside-1_809_1106' fill='white'%3E%3Crect x='5' y='2' width='9' height='9' rx='1'/%3E%3C/mask%3E%3Crect x='5' y='2' width='9' height='9' rx='1' stroke='white' stroke-width='2.4' mask='url(%23path-2-inside-1_809_1106)'/%3E%3C/svg%3E%0A");
        }

        background-color: $blue !important;
        color : $white !important;
    }
}

.btn-select {
    letter-spacing: normal;

    &.bg-white {
        border: 1px solid #D5DBE5;

        &:hover {
            border: 1px solid #D5DBE5;
        }

    }
}

.btn-contact-add {
    padding-top: 6px;
    padding-bottom: 8px;
    padding-right: 8px;
    padding-left: 8px;
    line-height: 18px;
    font-size: 13px;
    box-shadow: 0px 2px 5px rgba(0, 158, 195, 0.05), 0px 3px 10px rgba(0, 0, 0, 0.05), inset 0px -2px 0px #E7E7E7;

    >i.vi.vi-add-circle::before {
        vertical-align: -6px;
    }

    &:hover {
        >i.vi.vi-add-circle::before {
            content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10' cy='10' r='10' fill='%23203087'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.28787 3.64109L9.28837 9.28835L3.64111 9.28784V10.7122H9.28787L9.28837 16.3594L10.7122 16.3589V10.7122H16.3589L16.3594 9.28835L10.7122 9.28784V3.64109H9.28787Z' fill='white'/%3E%3C/svg%3E%0A");
        }
    }
}

.btn.text-danger {
    &:hover {
        color: $white !important;
        background-color: $red !important;
    }
}

.btn-logout {
    padding: 0.875rem;
    border-radius: 6px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1), inset 0px -2px 0px #CEE2EF;

    &:hover {
        background-color: #CEE2EF !important;
    }

    >i::before {
        vertical-align: .5rem;
    }
}

.btn-close-comm {
    width: 20px;
    height: 20px;
    padding: 5.56px;

    &:hover {
        background-color: $red !important;
        color: $white !important;

        >i.vi.vi-times::before {
            content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.20311 4.9384L0.62166 1.35696L1.41856 0.560059L5.00001 4.14151L8.58144 0.560068L9.37834 1.35697L5.79691 4.9384L9.50165 8.64315L8.70475 9.44005L5.00001 5.7353L1.29525 9.44006L0.498352 8.64316L4.20311 4.9384Z' fill='white'/%3E%3C/svg%3E%0A");
        }
    }
}

// Badge

.badge.badge-copy {
    height: 20px;
    font-size: 11px;
    line-height: 15px;
    padding: 2.5px 8px;

    &:hover {
        background-color: $indigo !important;
        color: $white !important;

        >button>i::before {
            content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 2H9V3.2H3.2V10H2V3C2 2.44772 2.44772 2 3 2Z' fill='white'/%3E%3Cmask id='path-2-inside-1_809_1237' fill='white'%3E%3Crect x='4' y='4' width='9' height='10' rx='1'/%3E%3C/mask%3E%3Crect x='4' y='4' width='9' height='10' rx='1' stroke='white' stroke-width='2.4' mask='url(%23path-2-inside-1_809_1237)'/%3E%3C/svg%3E%0A");
        }
    }

    >.btn.btn-copy {
        padding: 0px;
        margin: 0px;
        height: 16px;

        &:hover {
            >i.vi.vi-copycontent::before {
                content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 2H9V3.2H3.2V10H2V3C2 2.44772 2.44772 2 3 2Z' fill='white'/%3E%3Cmask id='path-2-inside-1_809_1735' fill='white'%3E%3Crect x='4' y='4' width='9' height='10' rx='1'/%3E%3C/mask%3E%3Crect x='4' y='4' width='9' height='10' rx='1' stroke='white' stroke-width='2.4' mask='url(%23path-2-inside-1_809_1735)'/%3E%3C/svg%3E%0A");
            }

            background-color: $indigo !important;
            color : $white !important;
        }
    }
}

.badge.badge-open {
    height: 28px;
    font-size: 13px;
    line-height: 18px;
    padding: 5px 8px;
    box-shadow: 0px 2px 5px rgba(0, 158, 195, 0.05), 0px 3px 10px rgba(0, 0, 0, 0.05), inset 0px -2px 0px $gray100;

    &:hover {
        background-color: $blue !important;
        color: $white !important;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05), inset 0px -2px 0px $darklblue;

        >button {
            background-color: $blue !important;

            >i::before {
                content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6667 12.6667H3.33333V3.33333H7V2H3.33333C2.59333 2 2 2.6 2 3.33333V12.6667C2 13.4 2.59333 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V9H12.6667V12.6667ZM9.33333 2V3.33333H11.7267L7 8.06L7.94 9L12.6667 4.27333V6.66667H14V2H9.33333Z' fill='white'/%3E%3C/svg%3E%0A");
            }
        }
    }

    >.btn.btn-copy {
        padding: 0px;
        margin: 0px;
        height: 16px;
    }
}

.badge.badge-selected {
    height: 28px;
    font-size: 13px;
    line-height: 18px;
    padding: 5px 8px;
}

.badge.badge-status {
    height: 20px;
    font-size: 11px;
    line-height: 15px;
    padding: 2.5px 8px;

    >i.vi.vi-rectangle::before {
        vertical-align: 1px;
    }

    >i.vi.vi-rectangle-white::before {
        vertical-align: 1px;
    }

    >i.vi.vi-check::before {
        vertical-align: -4px;
    }
}

.badge.badge-guest {
    border-radius: 4px;
    padding: 4px 10px;
    height: 24px;
    font-weight: $font-weight-bolder;
    font-size: 12px;
    line-height: 16px;
}

// Navigation

.nav-tabs {
    border: none;

    .nav-link.active,
    .nav-item.show .nav-link {
        background-color: initial;
        border-bottom: 3px solid $blue;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        color: $blue !important;
        font-weight: $font-weight-bold;
    }

    .nav-link {
        font-size: 11px;
        line-height: 15px;
        font-weight: $font-weight-bolder;
        padding-bottom: 0.5rem;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 0px;
        border-radius: 0px;
        letter-spacing: 0.04em;
    }

    .nav-link:not(.active):hover {
        border-bottom: none;
        isolation: initial;
    }
}

.nav-pills {
    border: none;
    padding: 7px 8px;
    background-color: $gray50;
    border-radius: 6px;

    .nav-link.active,
    .nav-item.show .nav-link {
        background-color: $white;
        border: 1px solid #D5DBE5;
        box-shadow: 0px 2px 6px rgba(125, 130, 143, 0.25);
        color: $blue !important;
        font-weight: $font-weight-bold;
    }

    .nav-link {
        font-weight: $font-weight-normal;
        font-size: 13px;
        line-height: 18px;
        padding: 4px 10px;
        color: $gray700;
        background-color: transparent;
        text-align: center;
        border: 1px solid transparent;
        box-shadow: none;
        border-radius: 6px;

        .btn.btn-copy {
            padding: 0px;
            margin: 0px;
            height: 16px;
        }

    }

    .nav-link-splitter {
        width: 0px;
        height: 16px;
        border-right: 2px solid #D5DBE5;
        margin-top: 5px;
        margin-right: -2px;
    }
}

// Form

.form-control {
    border: 0 none;
    border-bottom: $input-border-width solid $input-border-color;
    border-radius: 0 0 0 0;
    padding-bottom: 3px;
    height: 24px;
    line-height: 20px !important;
    @include box-shadow('none');

    &:focus {
        border-color: $input-focus-border-color;
        border-width: 2px;
        padding-bottom: 2px;
        box-shadow: none;
    }

    &::placeholder {
        color: $input-placeholder-color;
        font-weight: $font-weight-normal;
        opacity: 1;
    }
}

.form-label {
    text-transform: uppercase;
    line-height: 15px;
    letter-spacing: 0.04em;
}

.form-select {
    appearance: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3 6L8 11L13 6H3Z' fill='%23464F60'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: calc(100% - 13px) center;
    background-size: 16px;
    padding-right: 32px;
    border-radius: 0 0 0 0;
    border: 0 none;
    border-bottom: $input-border-width solid $input-border-color;
    padding-bottom: 3px;
    height: 24px !important;
    line-height: 20px !important;
    @include box-shadow('none');

    &:disabled {
        background-image: none;
    }

    &:focus {
        box-shadow: none;
        border-color: $input-focus-border-color;
        border-width: 2px;
        padding-bottom: 2px;
    }

    &::placeholder {
        color: $input-placeholder-color;
        font-weight: $font-weight-normal;
        opacity: 1;
    }
}

.form-control-plaintext:disabled,
.form-control-plaintext.disabled {
    font-size: 13px;
    font-weight: $font-weight-bolder;
    line-height: 18px;
    color: $black;
}

.form-control-plaintext:disabled {
    font-size: 13px;
    font-weight: $font-weight-bolder;
    line-height: 18px;
    color: $black;
}

// Label

.label-options {
    font-weight: $font-weight-bolder;
    font-size: 15px;
    line-height: 20px;
}

.label-radio {
    font-weight: $font-weight-bolder;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.04em;
}

.col-form-label.label-communication {
    font-weight: $font-weight-bolder;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $gray400;
    padding: 0px;
    padding-top: 3px;
}

// Input group

.input-group>.form-control,
.input-group>.form-select,
.input-group>.input-group-text,
.input-group>.btn {
    border: $input-btn-border-width solid $gray-400;
    @include border-radius($input-border-radius, 0);
    @include box-shadow($input-box-shadow);
    box-shadow: inset 0px -2px 0px #CEE2EF;

    &:focus {
        @if $enable-shadows {
            @include box-shadow($input-box-shadow, $input-focus-box-shadow);
        }

        @else {
            box-shadow: $input-focus-box-shadow;
        }
    }

    // Placeholder
    &::placeholder {
        font-weight: 400;
    }
}

.input-group-lg>.form-control,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
    height: 40px !important;
    padding: 0.75rem 0.75rem !important;
    padding-right: 1rem !important;
    border: $input-btn-border-width solid $gray-400 !important;
    @include font-size($font-size-base * 0.75);
    @include border-radius($input-border-radius-lg);

    &:disabled {
        box-shadow: 0px 2px 5px rgba(0, 158, 195, 0.05), 0px 3px 10px rgba(0, 0, 0, 0.05), inset 0px -2px 0px $gray100;
    }
}

.input-group-lg>.form-select {
    height: 40px !important;
    padding-left: 0.75rem;
    padding-bottom: 0.75rem;
    padding-top: 0.75rem;
    padding-right: 32px;
    border: $input-btn-border-width solid $gray-400 !important;
    @include font-size($font-size-base * 0.75);
    @include border-radius($input-border-radius-lg);
}

.input-group-lg>.form-control {
    font-weight: $font-weight-normal;
}

.input-group-lg>:not(:first-child) {
    border-left: 1px solid $stroke !important;
}

.dropdown-item-lg {
    @include font-size($input-font-size);
    padding: $input-padding-y-lg $input-padding-x-lg;
}

// Modals

h6.modal-title {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    color: $gray700;
}

@include media-breakpoint-up(sm) {

    // Automatically set modal's width for larger viewports
    .modal-dialog {
        max-width: $modal-md;
        margin: $modal-dialog-margin $modal-dialog-margin;
    }

    .modal-dialog-scrollable {
        height: subtract(100%, $modal-dialog-margin * 2);
    }

    .modal-dialog-centered {
        min-height: subtract(100%, $modal-dialog-margin * 2);
    }

    .modal-content {
        @include box-shadow($modal-content-box-shadow-sm-up);
    }

    .modal-sm {
        max-width: $modal-sm;
    }
}

@include media-breakpoint-up(md) {

    // Automatically set modal's width for larger viewports
    .modal-dialog {
        max-width: $modal-md;
        margin: $modal-dialog-margin-y-sm-up auto;
    }

    .modal-dialog-scrollable {
        height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);
    }

    .modal-dialog-centered {
        min-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);
    }

    .modal-content {
        @include box-shadow($modal-content-box-shadow-sm-up);
    }

    .modal-sm {
        max-width: $modal-sm;
    }
}

@include media-breakpoint-up(lg) {

    .modal-lg,
    .modal-xl {
        max-width: $modal-lg;
    }
}

@include media-breakpoint-up(xl) {
    .modal-xl {
        max-width: $modal-xl;
    }
}

.btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23203087'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;

}

.vrc-status {
    font-size: $font-size-base * .725;
    font-weight: 600;
}

.expanded-row {
    background-color: $gray100 !important;
    border-bottom-color: $gray200 !important;
}

.expanded-body {
    background-color: $back_table !important;
}

// Headers

h6 {
    line-height: 22px;
}

h5 {
    line-height: 25px;
}

h6.table-body-header {
    font-weight: $font-weight-bold;
    font-size: 15px;
    line-height: 20px;
    text-transform: capitalize;
    color: $gray700;
    margin-bottom: 8px;
}

// Card

.card.card-shadow {
    background: #FFFFFF;
    border: none;
    box-shadow: 0px 3px 10px rgba(0, 158, 195, 0.05);
    border-radius: 6px;
}

.sendto-cc-text-style{
    font-size: 13px;
    font-weight: $font-weight-bolder;
    line-height: 18px;
    color: $black;
}
.version{
    font-size: 12px;
}
.theme-background-dev{
    background-color: $green !important;
}
.theme-background-test{
    background-color: $lightblue !important;
}