@import './vrc-theme-variables';

.p-component {
    font-family: $font-family-base;
    @include font-size($font-size-base);
    font-weight: normal;
}

.pi {
    @include font-size($font-size-base);
}

// file upload

.p-fileupload-content {
    padding: 1rem;
}

.p-fileupload .p-fileupload-content {
    padding: 0.5rem 1rem !important;
}

.p-fileupload .p-fileupload-buttonbar {
    padding: 0.25rem 1rem !important;
}

.p-fileupload .p-fileupload-row>div {
    padding: 0.5rem 1rem;
}

// table
.p-datatable-wrapper>table {
    min-width: 800px
}

.p-sortable-column-icon {
    color: $gray700;
    padding: 0.25rem;
}

.p-column-filter-menu-button {
    color: $gray700 !important;
    border-radius: $border-radius;
    height: $font-size-base;

    &:focus {
        box-shadow: none;
    }
}

.p-datatable .p-datatable-thead>tr>th {
    padding: 8.5px 16px;
    background-color: $gray50 !important;
    color: $gray700 !important;
    text-transform: uppercase;
    border: none;
    font-size: 11px;
    line-height: 15px;
    height: 32px;
    font-weight: $font-weight-bolder;
    letter-spacing: 0.04em;
}

.p-datatable .p-sortable-column:focus {
    box-shadow: none;
}

.p-datatable .p-datatable-tbody>tr>td {
    border-width: 0;
    border: none;
    color: $gray700;
    // height        : 48px;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-right: 1rem;
    padding-left: 1rem;
    font-weight: $font-weight-normal;
    font-size: 14px;
    line-height: 18px;
}


.rowexpansion {
    overflow-y: auto;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: $gray0;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background: $gray100;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: $gray500;
}

.p-datatable .p-column-resizer {
    border-right-style: solid !important;
    border-right-color: $gray100 !important;
    border-right-width: 2px !important;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
    height: 80% !important;
}

.p-datatable .p-datatable-header {
    background: $white;
    color: $gray700;
    border: solid $white;
    border-width: 1px 0 0 0;
    padding: 1rem 1rem;
    font-weight: $font-weight-bold;
}

.p-datatable .p-datatable-tbody>tr {
    padding: 0px;
    border-bottom: 2px solid $grayBG;
    background: $white;
    color: $gray700;
    transition: box-shadow 0.15s;
}

.p-paginator .p-paginator-current {
    padding: 12px 16px;
    height: 40px;
    background: $white;
    box-shadow: 0px 2px 5px rgba(0, 158, 195, 0.05), 0px 3px 10px rgba(0, 0, 0, 0.05), inset 0px -2px 0px #E7E7E7;
    border-radius: 6px;
    font-weight: $font-weight-bolder;
    font-size: 12px;
    line-height: 16px;
    border: none;
    color: $gray700;
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);
    margin-right: 8px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    height: 40px;
    background: $white;
    box-shadow: 0px 2px 5px rgba(0, 158, 195, 0.05), 0px 3px 10px rgba(0, 0, 0, 0.05), inset 0px -2px 0px #E7E7E7;
    border-radius: 6px;
    border: none;
    margin-right: 8px;

    >span {
        color: $gray700;
        font-weight: $font-weight-bolder;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.5);

        &.p-disabled {
            color: $gray300;
        }
    }
}

.p-paginator .p-paginator-pages .p-paginator-page {
    padding: 10px 15px;
    height: 40px;
    background: $white;
    box-shadow: 0px 2px 5px rgba(0, 158, 195, 0.05), 0px 3px 10px rgba(0, 0, 0, 0.05), inset 0px -2px 0px #E7E7E7;
    border-radius: 6px;
    border: none;
    color: $gray700;
    font-weight: $font-weight-bolder;
    font-size: 14px;
    line-height: 19px;
    margin-right: 8px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05), inset 0px -2px 0px #122274;
    background: $blue;
    color: $white;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: $grayBG;
    color: $gray700;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: $grayBG;
    color: $gray700;
}

.p-dropdown:not(.p-disabled):hover {
    background: $grayBG;
    color: $gray700;
}

.p-paginator .p-dropdown {
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 16px;
    padding-right: 4px;
    height: 40px;
    background: $white;
    box-shadow: 0px 2px 5px rgba(0, 158, 195, 0.05), 0px 3px 10px rgba(0, 0, 0, 0.05), inset 0px -2px 0px #E7E7E7;
    border-radius: 6px;
    border: none;
    align-items: center;
    margin-right: 8px;
    margin-left: 0px;
}

.p-paginator .p-dropdown .p-dropdown-label {
    padding: 0px;
    font-weight: $font-weight-bolder;
    font-size: 14px;
    line-height: 19px;
    color: $gray700;
}

.p-dropdown .p-dropdown-trigger {
    color: $gray700;
}

// .p-datatable .p-paginator-bottom {
//     margin-bottom: 30px;
// }

.p-datepicker-trigger.p-ripple.p-button.p-component.p-button-icon-only {
    background-color: $blue !important;
}

// Table filter fields
.p-column-filter-matchmode-dropdown.p-dropdown.p-component {
    height: calc(1em + 0.4rem + 2px);
}

.p-dropdown-label.p-inputtext {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
}

.p-button-outlined.p-button.p-component {
    height: calc(1em + 0.4rem + 2px);
}

.p-inputnumber-button.p-inputnumber-button-up.p-button.p-component.p-button-icon-only {
    height: calc((1em + 0.4rem + 2px)/2);
    background-color: $blue;
    // height: 0.5rem;
    width: 1.5rem;
}

.p-inputnumber-button.p-inputnumber-button-down.p-button.p-component.p-button-icon-only {
    height: calc((1em + 0.4rem + 2px)/2);
    background-color: $blue;
    // height: 0.5rem;
    width: 1.5rem;
}

.p-element.p-button-icon-only.p-inputnumber-button.p-inputnumber-button-up.p-button p-component {
    height: calc((1em + 0.4rem + 2px)/2) !important;
}


.p-datatable .p-sortable-column .p-column-title,
.p-datatable .p-sortable-column .p-sortable-column-badge,
.p-datatable .p-sortable-column .p-sortable-column-icon {
    vertical-align: inherit !important;
}

.p-column-filter-menu-button.p-link {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    height: calc(1em + 0.3rem + 2px);
    width: 1.5rem;
    margin-left: 0.3rem;
}


.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button:hover {
    border-radius: 20%;
    color: $white;
    background: $info;
    background-color: $info;
}

// virtual scrooler
.p-virtualscroller .p-virtualscroller-header {
    padding: 0.5rem 0.5rem;
}

// date picker
.p-datepicker-calendar td {
    padding: 0.1rem;
}

.p-datepicker table td {
    // padding: 0.1rem;
    padding: 0.05rem;
}

.p-datepicker table td>span {
    // width : 2rem;
    // height: 2rem;
    width: 1rem;
    height: 1rem;
    line-height: 1.1rem;
    @include font-size($font-size-base);
}

.p-datepicker-header {
    height: 2.5rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
    border-radius: 20%;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-previous {
    border-radius: 20%;
}

.p-datepicker .p-timepicker span {
    @include font-size($font-size-base);
}

p-datepicker .p-timepicker button {
    width: 1rem;
    height: 1rem;
}

.p-inputtext.p-component {
    height: calc(1em + 0.4rem + 2px);
    line-height: 1.1rem;
    @include font-size($font-size-base);
    padding-top: 0.15rem;
    padding-bottom: 0.15rem;
}

.p-datepicker table th {
    padding: 0.2rem;
}

.p-datepicker table th>span {
    width: 1.5rem;
    height: 1.5rem;
}

.p-datepicker table {
    @include font-size($font-size-base);
    margin: 0.5rem 0;
}

// .btn:focus,
// .btn.focus {
//     outline   : 0;
//     box-shadow: none;
// }

.p-button {
    @include font-size($font-size-base);
    padding: 0.15rem 0.75rem;
}

button.p-button.p-component {
    height: calc(1em + 0.4rem + 2px);
}

.p-button.p-component.p-ripple {
    line-height: 1.1rem;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    background: $blue;
}

.p-button.p-button-sm {
    @include font-size($font-size-sm);
    padding: 0.15rem 0.75rem;
}

// check box
.p-checkbox {
    width: 1rem;
    height: 1rem;
}

.p-checkbox .p-checkbox-box {
    width: 1rem;
    height: 1rem;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: 0.15s;
    color: $white;
    @include font-size($font-size-base);
}

// drop down
.p-inputtext {
    @include font-size($font-size-base);
    padding: 0.5rem 0.75rem;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    background-color: $blue;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
    background: $blue;
    margin-left: 0.5rem;
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
    color: $dark;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: $blue;
}