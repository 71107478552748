@import "bootstrap/scss/functions";

$blue        :#203087;
$hover_blue  :#2E40A3;
$darklblue   :#122274;
$stroke_focus:#88BDDD;
$white       :#FFFFFF;
$gray0       :#F7F9FC;
$gray50      :#E9EDF5;
$gray100     :#D5DBE5;
$gray200     :#BCC2CE;
$gray300     :#A1A9B8;
$gray400     :#868FA0;
$gray500     :#687182;
$gray600     :#5A6376;
$gray700     :#464F60;
$black       :#171C26;
$stroke      :#E9EDF5;
$lightblue   :#0066AB;
$lightblueBG :#F4FAFC;
$grayBG      :#F3F3F3;
$stroke_edit :#127CBD;
$red         :#EF5466;
$redish      :#FADEE1;
$blueish     :#B3DFEA;
$greenish    :#D1EEE2;
$indigo      :#5E5ADB;
$back_table  :#F3F6FC;
$green       :#79CAA8;

$theme-colors : (
  "primary": $blue,
  "secondary": $white,
  "success": $green,
  "success-1": $greenish,
  "info": $blueish,
  "info-1": $stroke_focus,
  "warning": $gray0,
  "danger": $red,
  "danger-1": $redish,
  "light": $grayBG,
  "dark": $black,
  "lightblue": $lightblueBG,
  "gray-0": $gray0,
  "gray-50": $gray50,
  "gray-100": $gray100,
  "gray-700": $gray700)
  ;

$font-family-sans-serif: "Open Sans",
system-ui,
-apple-system,
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
"Noto Sans",
"Liberation Sans",
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji" !default;

// Common

$font-family-base   : $font-family-sans-serif;
$font-size-base     : 1rem;
$line-height-base   : 1;
$font-weight-normal : 400;
$font-weight-bold   : 700;
$font-weight-base   : 500;
$font-weight-bolder : 600;
$grid-gutter-width  : 0.3rem;
$box-shadow-inset   : inset 0px -2px 0px #CEE2EF;
$border-width       : 1px;
$box-shadow-sm      : 0px 1px 0px $gray50;
$spacer             : 1rem;
$spacers            : (0: 0,
  1: $spacer * .125,
  2: $spacer * .25,
  3: $spacer * .5,
  4: $spacer * .75,
  5: $spacer * 1,
  6: $spacer * 2,
);

$border-radius-lg:0.375rem;
$h1-font-size    : $font-size-base * 2.5;
$h2-font-size    : $font-size-base * 2;
$h3-font-size    : $font-size-base * 1.75;
$h4-font-size    : $font-size-base * 1.5;
$h5-font-size    : $font-size-base * 1.125;
$h6-font-size    : $font-size-base;

// Buttons

$input-btn-line-height         : 16px;
$input-btn-font-size           : 12px;
$input-btn-font-size-lg        : 13px;
$input-btn-font-size-sm        : 13px;
$btn-font-weight               : $font-weight-bolder;
$input-btn-padding-y-lg        : 11px;
$input-btn-padding-x-lg        : 1rem;
$input-btn-padding-y           : .5rem;
$input-btn-padding-x           : 1rem;
$input-btn-padding-y-sm        : 0.313rem;
$input-btn-padding-x-sm        : .5rem;
$input-btn-focus-width         : .3rem;
$input-btn-focus-color-opacity : .3;
$input-btn-border-width        : 0px;

// Badge

$badge-border-radius : 4px;
$badge-font-weight   : $font-weight-bolder;

// Form

$input-font-size          : 15px;
$input-line-height        : $line-height-base * 1.25;
$input-font-weight        : $font-weight-bolder;
$input-color              : $black;
$input-focus-color        : $input-color;
$input-padding-y          : 0px;
$input-padding-x          : 0px;
$input-arrow-height       : add($input-line-height * .4em, $input-padding-y);
$form-label-margin-bottom : 4px;
$form-label-font-size     : 11px;
$form-label-font-weight   : $font-weight-bolder;
$form-label-color         : $gray400;
$input-placeholder-color  : $gray500;
$input-border-color       : $stroke_focus;
$input-border-width       : 1px;
$form-select-font-size    : 15px;
$form-select-line-height  : $line-height-base * 1.25;

$form-group-margin-bottom : 0.5rem;
$input-group-font-size-lg : $font-size-base * 0.75;

$input-disabled-border-color : $white;
$form-select-disabled-bg     : $white;
$input-disabled-bg           : $white;
$grid-gutter-width           : 0.3rem;
$body-color                  : $black;
$body-bg                     : $white;
$input-bg                    : $body-bg;
$input-focus-bg              : $input-bg;
$component-active-bg         : $blue;
$input-focus-border-color    : $stroke_edit;
$input-focus-box-shadow      : 0px 3px 10px rgba(0, 0, 0, 0.1),
inset 0px -2px 0px $stroke_edit;
$headings-font-weight : 700;

// Navs

$nav-link-font-size  : $font-size-base * 0.688;
$nav-link-font-weight: $font-weight-bold;
$nav-link-padding-y  : .75rem;
$nav-link-padding-x  : 1rem;

// Modals

$modal-dialog-margin-y-sm-up : 1rem;
$modal-sm                    : auto;
$modal-md                    : 700px;
$modal-lg                    : 800px;
$modal-xl                    : 1140px;